import React, { Component, type SyntheticEvent } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import Button, { type Appearance } from '@atlaskit/button';
import AkInlineDialog, { type Placement } from '@atlaskit/inline-dialog';
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { type FireClickEvent } from '../../common/types/analytics';
import { buttonActionTypes } from '../../common/types/button';
import withEvents from '../../controllers/engagement-with-events';
import withMessage from '../../controllers/engagement-with-message';
import HtmlContent from '../../controllers/utils/html-content';

const baselineUnit = token('space.050', '4px');

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img`
	width: 100%;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContainer = styled.div`
	margin-top: ${baselineUnit};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BodyContainer = styled.div`
	padding-top: ${baselineUnit};
	padding-bottom: ${baselineUnit};
	color: ${token('color.text', colors.N900)};
`;

export type Action = {
	type: string;
	// we only support links at this time
	data?: ActionData;
};

export type ActionData = {
	target: string;
	url: string;
};

type Props = {
	fireClickEvent: FireClickEvent;
	target: string;
	body: string;
	children: JSX.Element;
	imageAlt?: string;
	imageSrc?: string;
	order: string[];
	position: Placement;
	primaryAction?: Action;
	primaryText?: string;
	secondaryAction?: Action;
	secondaryText?: string;
	title: string;
	engagementId?: string;
};

type OnClick = (e: SyntheticEvent) => void;

// eslint-disable-next-line @repo/internal/react/no-class-components
class InlineDialog extends Component<Props> {
	static defaultProps = {
		children: null,
		order: ['title', 'image', 'body'],
		position: 'bottom left',
	};

	getPrimaryButton() {
		const { primaryAction, primaryText } = this.props;

		return this.getActionButton('primary', this.onPrimaryClick, primaryAction, primaryText);
	}

	getSecondaryButton() {
		const { secondaryAction, secondaryText } = this.props;

		return this.getActionButton(
			'subtle-link',
			this.onSecondaryClick,
			secondaryAction,
			secondaryText,
		);
	}

	onPrimaryClick = () => {
		this.props.fireClickEvent('primary');
	};

	onSecondaryClick = () => {
		this.props.fireClickEvent('secondary');
	};

	getActionButton(
		appearance: Appearance | undefined,
		onClick: OnClick,
		action?: Action,
		text?: string,
	) {
		const type = action ? action.type : buttonActionTypes.DISMISS;

		switch (type) {
			case buttonActionTypes.LINK: {
				const actionData: ActionData | undefined = action!.data;

				return (
					<Button
						href={actionData!.url}
						target={actionData!.target}
						appearance={appearance}
						onClick={onClick}
					>
						{text}
					</Button>
				);
			}
			default:
				return (
					<Button appearance={appearance} onClick={onClick}>
						{text}
					</Button>
				);
		}
	}

	render() {
		const { children, title, imageSrc, imageAlt, body, position, order, secondaryText } =
			this.props;

		const messageContent: Record<string, JSX.Element | null> = {
			image: null,
			title: null,
			body: null,
		};

		if (title) {
			messageContent.title = <h4 key="title">{title}</h4>;
		}

		if (imageSrc) {
			messageContent.image = <Image key="img" src={imageSrc} alt={imageAlt} />;
		}

		messageContent.body = (
			<BodyContainer key="body">
				<HtmlContent html={body} />
			</BodyContainer>
		);

		const orderedContent = order.map((c) => messageContent[c]);

		const content = (
			<div>
				<ContentContainer>{orderedContent}</ContentContainer>

				<ButtonContainer>
					{this.getPrimaryButton()}

					{secondaryText ? this.getSecondaryButton() : null}
				</ButtonContainer>
			</div>
		);

		return (
			<AkInlineDialog content={content} placement={position} isOpen>
				{children}
			</AkInlineDialog>
		);
	}
}

const InlineDialogWithEvents = withEvents(InlineDialog);

export { InlineDialog, InlineDialogWithEvents };

export default withMessage(InlineDialogWithEvents, 'inlineDialog');
