import React from 'react';
import { EngagementInlineDialog } from '@atlassiansox/engagekit-ts';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

// ssrPlaceholderReplaceIdProp is added to LOADABLE AFTER_PAINT priority elements to identify and exclude them
// from TTVC calculations if their size and position doesn't change after hydration

// adding loadable placeholderId around EngagementInlineDialog children here to avoid adding it within EngagementInlineDialog (AtlasKit component)
export const EngagementInlineDialogWrapper = (props) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();

	// there are multiple engagement inline dialog loaders used for navigation, so we're using
	// dataVC to distinguish for reporting
	const { children, dataVC, ...restProps } = props;
	return (
		<EngagementInlineDialog {...restProps}>
			<div data-vc={dataVC} {...ssrPlaceholderIdProp}>
				{children}
			</div>
		</EngagementInlineDialog>
	);
};
